<template>
  <div class="Box">
    <p style="color:#F56C6C">添加分类</p>
    <div>
      <el-input
        v-model="classify"
        placeholder="请输入标签"
        style="width: 200px"
      ></el-input>
      <el-button type="primary" class="btn" @click="addclassify">添加标签</el-button>
    </div>
    <div style="margin-top: 20px">
      <el-input
        v-model="Tags"
        placeholder="请输入属性"
        style="width: 200px"
      ></el-input>
      <el-button type="primary" class="btn" @click="AddArticleAttribute">添加属性</el-button>
    </div>

      <div class="text">
        <p class="p1" style="color:#67C23A">标签</p>
      <p class="p2" style="color:#67C23A">属性</p>
      </div>
    <div class="FatherBox">
      <!-- 标签 -->
      <div class="classify">
      <el-tag v-for="cls in classifys" :key="cls.ID" :type="cls.type" style="margin: 3px">
        {{ cls.Tags }}
      </el-tag>
    </div>

    <!-- 属性 -->
    <div class="tags">
      <el-tag v-for="tag in tags" :key="tag.ID"  :type="tag.type" style="margin: 3px">
        {{ tag.Attribute }}
      </el-tag>
    </div>
    </div>
  </div>
</template>
<script>
import { attrList, tagList, attrAdd, tagAdd } from '@/api/adminApi.js'
export default {
  data () {
    return {
      classify: '', // 标签
      Tags: '', // 属性
      tags: [], // 现有属性
      classifys: [ // 现有标签
        //  { name: '情感', type: '' },
        // { name: '娱乐', type: 'success' },
        // { name: '新闻', type: 'info' },
        // { name: '军事', type: 'warning' },
        // { name: '视力', type: 'danger' },
        // { name: '财经', type: 'danger' },
        // { name: '疫情', type: 'danger' },
        // { name: '推荐', type: 'danger' }
      ]
    }
  },
  mounted () {
    this.GetTagInfo()// 获取标签列表
    this.GetAttributeInfo() // 获取属性列表
  },
  methods: {
    addclassify () { // 添加文章标签
      const data = {
        tagName: this.classify
      }
      tagAdd(data).then(res => {
        if (res.status.code == 1) {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          const Obj = {
            Tags: this.classify
          }
          this.classifys.push(Obj) // 成功则push到现有数组
          this.classify = '' // 清空输入框
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    },
    AddArticleAttribute () { // 添加文章属性
      const data = {
        attrName: this.Tags
      }
      attrAdd(data).then(res => {
        if (res.status.code == 1) {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          const Obj = {
            Attribute: this.Tags
          }
          this.tags.push(Obj)// 成功则push到现有数组
          this.Tags = '' // 清空输入框
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    },
    GetTagInfo () { // 获取标签列表
      tagList().then(res => {
        // console.log(res);
        this.classifys = res.data
      }).catch(err => { console.log(err) })
    },
    GetAttributeInfo () { // 获取属性列表
      attrList().then(res => {
        // console.log(res);
        this.tags = res.data
      }).catch(err => { console.log(err) })
    }
  }
}
</script>
<style lang="scss" scoped>
.Box {
  display: flex;
  width: 1000px;
  flex-direction: column;
  .btn {
    margin-left: 6px;
  }
}

.tags{
  display: flex;
  width: 430px;
  flex-wrap:wrap;
  -webkit-flex-wrap:wrap;
  border: 1px solid #cccccc;
  margin: 0 auto;
  margin-top: 50px;
  margin-left: 80px;
}
.classify{
   display: flex;
  width: 430px;
  flex-wrap:wrap;
  -webkit-flex-wrap:wrap;
  border: 1px solid #cccccc;
  margin: 0 auto;
  margin-top: 50px;
}
.FatherBox{
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
}
.text{
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
  position: relative;
  top: 70px;
}

</style>
